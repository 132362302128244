<template>
  <div>
    <!-- table -->
      <!-- :show="isLoading" -->
    <b-overlay
      rounded="sm"
    >
      <b-card class="p-2">
        <vue-good-table
          theme="polar-bear"
          mode="remote"
          :search-options="{
            enabled: false,
          }"
          :pagination-options="{
            enabled: true,
            perPage: serverParams.perPage,
            perPageDropdown: [5, 10, 20, 50, 100],
            dropdownAllowAll: false,
          }"
          :select-options="{
            enabled: true,
            selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :is-loading.sync="isLoading"
          :total-rows="totalRecords"
          :columns="columns"
          style-class="vgt-table condensed bordered"
          compact-mode
          :rows="all_my_applications"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-selected-rows-change="onSelectionChange"
        >
          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span
              v-if="['created_on', 'interview_date_time'].includes(props.column.field)"
              class="mt-2"
            >
              <!-- <flat-pickr
                v-model="created_on_filter"
                :config="configPicker"
                class="vgt-input"
                placeholder="Filter Applied On"
                @input="(value) => updateFilters(props.column.field, value)"
              /> -->

              <b-button
                variant="warning"
                @click="showDateRangeFilter(true, props.column.field)"
              >
                Click to filter the date
              </b-button>
              <b-button
                v-if="props.column.field in serverParams.columnFilters"
                class="ml-2"
                @click="resetDateRangeFilter(true, props.column.field)"
              >Reset</b-button>
            </span>
          </template>

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Status -->
            <span v-if="props.column.field === 'interview_date_time'">
              <div
                class="d-flex flex-column justify-content-start"
                style="margin-block: 0.2rem; gap: 0.2rem 0.5rem;"
              >
                <p>{{ props.row.interview_date_time ? $formatDatetime(props.row.interview_date_time): '-' }}</p>
                <div class="flex-break" />
                <div>
                  <template
                    v-if="props.row.interview_request_status === 'accepted'"
                  >
                    <b-badge :variant="'success'">
                      Accepted
                    </b-badge>
                  </template>
                  <template
                    v-else-if="props.row.interview_request_status === 'sent' && $isNotEmpty(props.row.interview_date_time)"
                  >
                    <b-button
                      variant="success"
                      @click="processInterviewRequest(props.row.id, 'accepted')"
                    >
                      Accept
                    </b-button>
                    <b-button
                      class="ml-1"
                      variant="danger"
                      @click="processInterviewRequest(props.row.id, 'rejected')"
                    >
                      Reject
                    </b-button>
                  </template>
                </div>
              </div>
              <!-- <b-badge
                :variant="
                  cleanedInterviewDate(props.row)[0] ? 'success' : 'danger'
                "
              >
                {{
                  cleanedInterviewDate(props.row)[1].length > 2
                    ? cleanedInterviewDate(props.row)[1]
                    : 'Not Yet Updated'
                }}
              </b-badge>
              <div
                v-if="cleanedInterviewDate(props.row)[1].length > 10"
                class="d-flex justify-content-center"
              >
                <b-badge
                  style="margin: 10px"
                  variant="success"
                  class=""
                >Accept</b-badge>
                <b-badge
                  style="margin: 10px"
                  variant="danger"
                  class=""
                >Reject</b-badge>
                <b-badge
                  style="margin: 10px"
                  variant=""
                  class=""
                >Counter Propose</b-badge>
              </div> -->
            </span>

            <span
              v-else-if="
                props.column.field === 'interview_status'
              "
            >
              <b-badge variant="info">
                {{
                  $length(props.row.interview_status) > 3
                    ? props.row.interview_status
                    : 'Not Yet Updated'
                }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'actions'">
              <feather-icon
                class="ml-2"
                icon="TrashIcon"
                size="24"
                @click="showDelete(props.row)"
              />
            </span>

            <!-- Column: Common -->
            <span v-else>
              
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <!-- <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pagination.perPage"
                  :options="[5, 10, 20, 50, 100]"
                  class="mx-1"
                />
                <span class="text-nowrap"> of {{ pagination.totalRows }} entries </span>
              </div>
              <div>
                <b-pagination
                  v-model="pagination.currentPage"
                  :value="1"
                  :total-rows="pagination.totalRows"
                  :per-page="pagination.perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template> -->
        </vue-good-table>
      </b-card>
    </b-overlay>
    <ProfileCompletionForm
      :activate-popup="popupProfileQuestions"
      :hide-header="true"
      @open-completion-form="activateTypeForm"
    />

    <b-modal
      v-model="dateRangeFilter"
      centered
      hide-footer
      title="Choose the desired date range to filter the data"
      class="bg-danger"
      size="lg"
    >
      <v-date-picker
        v-model="startEndDate"
        mode="date"
        :masks="masks"
        color="orange"
        :columns="2"
        is-range
        is-expanded
      />
    </b-modal>
  </div>
</template>

<script>
import { format } from 'date-fns'

import {
  BCard,
  BBadge,
  BOverlay,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'

import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'

import {
  applicationStatusOptions,
  applicationTypeOptions,
} from '@/constants/datastore'

import { dateRangePickerMixins } from '@/components/date-range-picker/dateRangePickerMixins'
import { completionMixins } from '@/components/mixins/CompletionMixins'

export default {
  components: {
    VueGoodTable,
    BCard,
    BPagination,
    BFormSelect,
    BBadge,
    BOverlay,

    flatPickr,
  },
  mixins: [completionMixins, dateRangePickerMixins],
  data() {
    return {
      selected_applications: [],
      itemsPerPage: 6,
      isMounted: false,
      event_id: null,
      preview_event_id: null,
      preview_job: false,
      all_my_applications: [],
      all_interview_types: {
        speed_interview: 'Virtual Speed Interview',
        virtual: 'Virtual Interview',
        physical: 'In-Person Interview',
        none: 'Not Specified',
      },
      job_source_types: {
        upload: 'Uploaded',
        curated: 'Suggested By The System',
      },

      pageLength: 10,
      dir: false,

      columns: [
        {
          label: 'Event Name',
          field: 'event_details.event_name',
          width: '25ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Job Position',
          field: 'event_details.position',
          width: '25ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Employer Name',
          field: 'event_details.company',
          width: '300px',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Interview Date And Time',
          field: 'interview_date_time',
          width: '320px',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Application Status',
          field: 'application_status',
          width: '20ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
            filterDropdownItems: applicationStatusOptions,
          },
        },
        
        {
          label: 'Interview Status',
          field: 'interview_status',
          width: '20ch',
          filterOptions: {
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Applied On',
          field: 'created_on',
          width: '300px',
          type: 'date',
          dateInputFormat: 'do MMM yyyy HH:mm', // expects 2018-03-16
          dateOutputFormat: 'do MMM yyyy HH:mm', // outputs Mar 16th 2018
          tdClass: 'text-left',
          thClass: 'text-left',
          filterOptions: {
            customFilter: true,
            enabled: true,
            placeholder: '',
            trigger: 'enter',
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: '',
          },
        },
      ],
      rows: [],
      searchTerm: '',

      isLoading: true,

      // vue-good-table
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      configPicker: {
        altInput: true,
        enableTime: false,
      },
      configTimePicker: {
        altInput: true,
        noCalendar: true,
        enableTime: true,
      },
      created_on_filter: null,

      exportingCSV: false,
    }
  },

  computed: {
    currentApplicationsPage() {
      if (this.isMounted) {
        if (this.$refs.participating_table) {
          return this.$refs.participating_table.currentx
        }
        return 1
      }
      return 0
    },

    queriedApplications() {
      return this.$refs.participating_table
        ? this.$length(this.$refs.participating_table.queriedResults)
        : this.all_my_applications.length
    },
  },
  watch: {
  },
  mounted() {
    try {
      const next = this.$children[0].$refs.btnclose
      next.$el.addEventListener('click', this.onClickCloseButton, false)
    } catch (error) {}

    const incompleteProfile = this.checkProfileCompletion()

    if (incompleteProfile) {
      this.popupProfileQuestions = true
    }

    this.isMounted = true

    this.getMyApplications()
  },

  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getMyApplications()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    onColumnFilter(params) {
      this.updateParams(params)
    },
    updateFilters(field, value) {
      this.serverParams.columnFilters[field] = value
      this.updateParams(this.serverParams.columnFilters)
    },

    cleanedInterviewStatus(details) {
      if (details.interview_status.application_status.length > 0) {
        return [true, details.interview_status.application_status]
      }

      return [null, 'Pending Interview']
    },
    cleanedInterviewDate(details) {
      const int_date_time = details.interview_date_time
      if (int_date_time.date.length > 0) {
        return [
          true,
          `${int_date_time.date} (${int_date_time.start_time} - ${int_date_time.end_time})`,
        ]
      }

      return [null, 'Not Set']
    },
    getMyApplications() {
      this.isLoading = true

      // server pagination
      let query = ''
      query = `?params=${JSON.stringify(this.serverParams)}`

      this.$http
        .get(`/api/my-applications${query}`)
        .then(response => {
          this.all_my_applications = response.data.all_applications
          this.totalRecords = response.data.totalRecords

          this.isLoading = false
        })
        .catch(error => {})
    },

    approveJob(job_id, status) {
      this.$http
        .get(`/api/approve-job/${job_id}/${status}`)
        .then(response => {
          const { success } = response.data

          if (success) {
            const idx = this.all_my_applications.findIndex(
              x => x._id === job_id,
            )

            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Job Approval',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.all_my_applications.splice(idx, 1)
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title: 'Job Approval',
                  icon: 'AlertCircleIcon',
                  text: response.data.message,
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },
    deleteJob(event) {
      this.isLoading = true

      this.$http
        .delete(`/api/applications/${event.id}`, {})
        .then(response => {
          this.getMyApplications()

          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Account operations',
                icon: 'CheckCircleIcon',
                text: 'Application withdrawn successfully',
                variant: 'success',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
        .catch(error => {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Account operations',
                icon: 'AlertCircleIcon',
                text: 'Unable to withdraw application, please try again later',
                variant: 'danger',
                position: 'center',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
    showDelete(event) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to withdraw from "${event.event_details.position}"`,
          {
            title: 'Confirm application withdrawal',
            size: 'sm',
            okVariant: 'danger',
            okTitle: 'Withdraw',
            cancelTitle: 'Cancel',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          },
        )
        .then(value => {
          if (value) {
            this.deleteJob(event)
          }
        })
    },

    formatDate(dateTime) {
      return format(new Date(dateTime), 'do LLL, yyyy HH:mm')
    },
    processInterviewRequest(applicationId, status = null, resumeReviewed = null) {
      // applicationId = '3f6e8ae1-99b0-403f-aed4-9edf7bcb0b05' // test
      if (status) {
        const changeData = {
          id: applicationId,
          interview_request_status: status,
        }

        this.$http
          .put(`/api/application-status/${this.$route.name}`, { data: changeData })
          .then(response => {
            const { success, errors } = response.data
            if (success && !resumeReviewed) {
              this.$toastSuccess('ATS Notification', response.data.message)
            }

            if (errors && !resumeReviewed) {
              this.$toastDanger('ATS Notification', response.data.errors[0])
            }

            this.getMyApplications()
          })
          .catch(error => {
            if (!resumeReviewed) {
              this.$toastDanger('ATS Notification', 'Application status update has failed. Try again later.')
            }
          })
      }
    },
  },
}
</script>

<style lang="scss"></style>
